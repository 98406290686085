import { AuthenticatedUserMenu, MenuItem } from "@sal/portal";
import { Outlet, useNavigate } from "react-router-dom";
import {
	ShipmentDashboardMenuItem,
	usePortalApplicationContext,
	UserOrganisationToggle,
	VerticalNavigation
} from "@sal/onevent-portal";
import { Toolbar, useMediaQuery, useTheme } from "@mui/material";
import { SecurityContext } from "../constants";
import { useAuth } from "@sal/authentication.react";
import { VerticalNavigationActionButton } from "../components/VerticalNavigationActionButton";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import LocationOnOutlined from "@mui/icons-material/LocationOnOutlined";
import LocalShippingSharpIcon from "@mui/icons-material/LocalShippingSharp";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import GroupIcon from "@mui/icons-material/Group";
import Analytics from "@mui/icons-material/Analytics";
import EventIcon from "@mui/icons-material/Event";
import { MobileNavigation } from "../components/MobileNavigation";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import { TableChart } from "@mui/icons-material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SettingsIcon from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";
import { QuickSearch } from "../components/QuickSearch";

type Auth = ReturnType<typeof useAuth>;

export default function BasePage() {
	const theme = useTheme();
	const navigate = useNavigate();
	const auth = useAuth();
	const { organisations } = usePortalApplicationContext();

	const hasUserMenuPermission = auth.userHasPermission(SecurityContext.Host, "User_Menu");
	const hasUserMenuPermissionMobile = (auth: Auth) => auth.userHasPermission(SecurityContext.Host, "User_Menu"); //TODO: fix this
	const hasChangePasswordPermission = (auth: Auth) => auth.userHasPermission(SecurityContext.Host, "Change_Password");
	const tabletBreakpoint = useMediaQuery(theme.breakpoints.down("md"));

	const menuItems: Array<MenuItem> = [
		Object.assign(ShipmentDashboardMenuItem, {
			label: "Track",
			path: "/Track?intent=map",
			securityContext: "portal_Menu",
			securityContextPermission: "Web_Shipments",
			icon: <LocationOnOutlined color="inherit" fontSize="inherit" />
		}),
		{
			label: "Accounts",
			path: "/Accounts",
			securityContext: "portal_Menu",
			securityContextPermission: "Web_Accounts",
			icon: <ContactPageIcon color="inherit" fontSize="inherit" />
		},
		{
			label: "Estimates & Quotes",
			path: "/Estimate?intent=edit",
			securityContext: "portal_Menu",
			securityContextPermission: "Web_Estimate",
			icon: <RequestQuoteIcon color="inherit" fontSize="inherit" />
		},
		{
			label: "Transport",
			path: "/Transport",
			securityContext: "portal_Menu",
			securityContextPermission: "Web_Transport",
			icon: <LocalShippingSharpIcon color="inherit" fontSize="inherit" />
		},
		{
			label: "Schedules",
			path: "/Schedules",
			securityContext: "portal_Menu",
			securityContextPermission: "Web_Schedules",
			icon: <CalendarTodayIcon color="inherit" fontSize="inherit" />
		},
		{
			label: "Analytics",
			path: "/Analytics",
			securityContext: "portal_Menu",
			securityContextPermission: "Web_Analytics",
			icon: <Analytics color="inherit" fontSize="inherit" />
		},
		{
			label: "Users",
			path: "/Users",
			securityContext: "portal_Menu",
			securityContextPermission: "Web_Users",
			icon: <GroupIcon color="inherit" fontSize="inherit" />
		},
		{
			label: "Reports",
			path: "/Reports",
			securityContext: "portal_Menu",
			securityContextPermission: "Web_Reports",
			icon: <TableChart color="inherit" fontSize="inherit" />
		},
		{
			label: "TEU Calendar",
			path: "/TeuCalendar",
			securityContext: "portal_Menu",
			securityContextPermission: "Web_Teu_Calendar",
			icon: <EventIcon color="inherit" fontSize="inherit" />
		}
	];

	const mobileMenuItems: Array<MenuItem> = [
		Object.assign(ShipmentDashboardMenuItem, {
			label: "Track",
			path: "/Track",
			icon: <LocationOnOutlined color="inherit" fontSize="inherit" />
		})
	];

	const handleSettingIconClick = () => {
		navigate("MyAccount");
	};

	return (
		<Box display="flex" height="100vh" bgcolor="background.default">
			{tabletBreakpoint ? (
				<>
					<Box flexGrow={1} minWidth={0} display="flex" flexDirection="column">
						{auth.isLoggedIn && (
							<Box flexGrow={0}>
								<MobileNavigation
									permissions={{
										userMenu: hasUserMenuPermissionMobile,
										changePassword: hasChangePasswordPermission
									}}
									menuItems={mobileMenuItems}
								/>
							</Box>
						)}
						<Box
							flexGrow={1}
							overflow="auto"
							sx={{
								scrollbarGutter: "stable"
							}}
						>
							<Outlet />
						</Box>
					</Box>
				</>
			) : (
				<>
					{auth.isLoggedIn && (
						<Box flexGrow={0}>
							<VerticalNavigation
								width={{ expanded: 280, collapsed: 75 }}
								clientLogo={{
									expanded: "/images/mvgl_logo.png",
									collapsed: "/images/mvgl_logo_trunc.svg"
								}}
								components={{
									Header: VerticalNavigationActionButton
								}}
								MenuProps={{
									items: menuItems
								}}
								clientLogoStyle={{
									expanded: {
										height: "2.8rem"
									}
								}}
							/>
						</Box>
					)}
					{/* Set minWidth=0, to allow datagrid layouts with flex layout columns */}
					{/* https://github.com/mui/mui-x/issues/8895#issuecomment-1537134652 */}
					<Box flexGrow={1} minWidth={0} display="flex" flexDirection="column">
						{auth.isLoggedIn && (
							<Box flexGrow={0}>
								<AppBar
									position="static"
									sx={{
										borderBottom: "1px solid",
										borderColor: theme.palette.grey[200]
									}}
								>
									<Toolbar
										disableGutters
										sx={{
											alignItems: "stretch",
											paddingX: theme.spacing(4)
										}}
									>
										<Box display="flex" width="100%" alignItems="center">
											<Box
												display="flex"
												flexGrow="1"
												alignSelf="stretch"
												alignItems={"center"}
												color={theme.palette.text.primary}
											>
												<Box width="100%" minWidth="200px" maxWidth="500px" pr={5}>
													<QuickSearch />
												</Box>
											</Box>
											<Box
												flexGrow="0"
												display="flex"
												alignItems="center"
												alignSelf="stretch"
												gap={3}
												color={theme.palette.text.primary}
											>
												<Box alignItems="center" minWidth="300px" maxWidth="400px">
													<UserOrganisationToggle />
												</Box>
												{hasUserMenuPermission && (
													<AuthenticatedUserMenu
														onLogout={() => organisations.clearOrganisations()}
													/>
												)}
												<IconButton color="inherit" onClick={handleSettingIconClick}>
													<SettingsIcon />
												</IconButton>
											</Box>
										</Box>
									</Toolbar>
								</AppBar>
							</Box>
						)}
						<Box
							flexGrow={1}
							overflow="auto"
							sx={{
								scrollbarGutter: "stable"
							}}
						>
							<Outlet />
						</Box>
					</Box>
				</>
			)}
		</Box>
	);
}
